
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";


const Wrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: ${props=> props.hero?"#FFFFFF":"var(--tema4)"};
    
    color: ${props=> props.hero?"var(--tema4)":"#FFFFFF"};
    margin: 0.5rem 0 0.5rem 0;
    font-weight:bold;

    h1{
        text-transform: uppercase;
        font-size: 12px;
        border-bottom: ${props=>props.hero?"none":"solid 2px #36789B"};
    }
    h2{
        font-size: 12px;
        margin-bottom: 0.3rem;
    }
    h3{
        font-size: 12px;
        
        padding: 0.1rem 0 0.1rem 0;
        margin-bottom: 0.25rem;
    }
    span{
        font-size: 12px;
        padding: 0.25rem; 
        margin: 0.25rem 0.25rem 0.1rem 0.25rem;
        color: white;
        background-color: var(--color2);
        cursor: pointer;
    }

    @media (min-width: 768px) { 
        min-width:200px;
        width:200px;
        max-width:200px;
        min-height:200px;
        height:200px;
        max-height:200px;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 1024px) { 
        width:80%;
        margin-right: 0rem;
    }
`;

const TextZone = styled.div`
    text-align: left;
    font-size: 10px;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    max-width:50%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @media (min-width: 768px) { 
        text-align: center;
        max-width:100%;
    }

    img{
        height: 50px;
        width:100%;
        object-fit:contain;
        :checked{
            transform: scale(3)
        }   
    }

`



export const Card = ({ title = "", content1 = "", content2 = "", linkTitle = "", linkDestiny = "", external = false, hero=false, contentdiv=<></> }) => {
    return (
        <Wrapper hero={hero} >
            <TextZone hero={hero}>
                {contentdiv}
                <h1>{title}</h1>
                <h2>{content1}</h2>
                <h3>{content2}</h3>
            </TextZone>
            {!external ? (<span><Link to={linkDestiny?linkDestiny:"/"}>{linkTitle?linkTitle:"Ir al enlace"}</Link></span>) : (<span><a target={"_blank"} rel={"noreferrer"} href={linkDestiny}>{linkTitle}</a></span>)}
        </Wrapper>)
}



const Multicard = ({ data = [], hero=false, external=false }) => {

    const [count, setcount] = useState(0);

    useEffect(() => {
        const counter = setTimeout(() => {
            if (count === data.length - 1) {
                setcount(0);
            } else {
                setcount(count + 1);
            };
        }, 13000);

        return () => {
            clearTimeout(counter);
        };

    }, [count, data])

    return (
        <>
        <Card
            title={data[count].title}
            content1={data[count].content1}
            content2={data[count].content2}
            linkTitle={data[count].linkTitle}
            linkDestiny={data[count].linkDestiny}
            hero={hero}
            external={external}
        />
        </>
    )

}

export default Multicard;