import ImgLib from "./ImgLib";

const Courses = {
  data: [
    {
      id: 0,
      title: "Sistemas de Salud",
      titleeng: "",
      description:
        "La red QuEST LAC con el objetivo de promover el desarrollo de capacidades en materia de investigación de la región en diferentes niveles, desarrollará su primer curso corto sobre Sistemas en Salud.",
      descriptionENG: "",
      startDate: "Junio (fecha exacta pendiente por comunicar)",
      endDate: "",
      methodology: [
        "100% Virtual",
        "El presente curso brindará acceso a una nube con 4 plenarias con especialistas y material complementario de lectura.",
        "El participante podrá llevar la cursada al ritmo de su preferencia",
        "El participante tomará un breve cuestionario virtual para conseguir la constancia de participación a nombre de la Red.",
        "Entrega de constancias: Ultima semana del curso.",
      ],
      time: "",
      image: ImgLib[15],
      bannerImage: ImgLib[15],
      language: "ESP",
      proffesorId: [],
      registerLink: "https://forms.gle/3bPaxChzNvoEy9mP8",
      seeVideoESP: "",
      seeVideoENG: "",
      files: "",
    },
  ],
};

export default Courses;

/**{   
    "id": 1,
    "title":"webinar 1",
    "date":"Fecha 1",
    "time":"Hora 1",
    "image":"vacio",
    "description":"Es un evento tipo webinar perto es numero 1",
    "link":"/",
},**/
