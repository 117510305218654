import img1 from "@images/webinars/1.svg";
import img2 from "@images/webinars/2.svg";
import img3 from "@images/webinars/3.svg";
import img4 from "@images/webinars/4.svg";
import img5 from "@images/webinars/5.svg";
import img6 from "@images/webinars/6.png";
import img7 from "@images/webinars/7.png";
import img8 from "@images/webinars/8.png";
import img9 from "@images/webinars/9.png";
import img10 from "@images/webinars/x.png";
import img11 from "@images/webinars/x.png";
import img12 from "@images/webinars/x.png";
import img13 from "@images/webinars/x.png";
import img14 from "@images/webinars/x.png";
import img15 from "@images/courses/1.png";
import img16 from "@images/webinars/mayo31.png";
import img17 from "@images/webinars/20220628.png";
import img18 from "@images/webinars/web20220927.png";
import img19 from "@images/webinars/web20221025.png";
import img20 from "@images/webinars/web20221129.png";
import img21 from "@images/webinars/web28082023.jpg";
import img22 from "@images/webinars/web202302.png";
import img23 from "@images/webinars/web20230425.png";
import img24 from "@images/webinars/web20230530.png";
import img25 from "@images/webinars/web20230627.png";
import img26 from "@images/webinars/20230829.png";
import img27 from "@images/webinars/web20230926.png";
import img28 from "@images/webinars/web20231128.png";
import img29 from "@images/webinars/web20240227.png";
import img30 from "@images/webinars/web20240430.png";
import img31 from "@images/webinars/web20240625.png";

const ImgLib = [
  img1,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31
];

export default ImgLib;
