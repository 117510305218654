import React from 'react';
import Webinars from '@datasources/Webinars'
import Courses from '@datasources/Courses';
import Publications from '@datasources/Publications';
import Multicard from '@components/Multicard/Multicard';
import { Card } from '@components/Multicard/Multicard';
import styled from 'styled-components';


const Wrapper = styled.div`
    position: relative;
    padding:0.5rem;
    height:100%;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	align-content: stretch;
`;




const RightNavBar = ({ hero = false, extra = <></> }) => {
    return (
        <Wrapper hero={hero}>
            {extra}
            {Webinars["data"].length < 1 ? (
                null
            ) : (
                <Multicard
                    data={Webinars["data"].slice(-3).map((item) => {
                        return {
                            "title": item["title"],
                            "content1": item["date"]?"Fecha: "+item["date"]:"",
                            "content2": item["time"]?"Hora: "+item["time"]:"",
                            "linkDestiny": "/actividades/webinars",
                            "linkTitle": `Ver webinars`,
                        }
                    })}
                    hero={hero}
                />
            )}

            {Courses["data"].length < 1 ? (
                null
            ) : (
                <Multicard
                    data={Courses["data"].slice(-3).map((item) => {
                        return {
                            "title": item["title"],
                            "content1": item["startDate"]?"Apertura: "+item["startDate"]:"",
                            "content2": item["endDate"]?"Cierre: "+item["endDate"]:"",
                            "linkDestiny": "/actividades/cursos-cortos",
                            "linkTitle": `Ver cursos`,
                        }
                    })}
                    hero={hero}
                />
            )}

            {Publications["data"].length < 1 ? (
                null
            ) : (
                <Multicard
                    data={Publications["data"].slice(-3).map((item) => {
                        return {
                            "title": "Publicaciones",
                            "content1": item["title"] ? item["title"].substring(0, 60) + "..." : "",
                            "content2": item["author"] ? item["author"][0]["family"] + " et al" : "",
                            "linkDestiny": item["DOI"] ? "https://dx.doi.org/" + item["DOI"] : "",
                            "linkTitle": `Ver publicación`,
                        }
                    })}
                    hero={hero}
                    external={true}
                />
            )}


        </Wrapper>
    )
}

export default RightNavBar;