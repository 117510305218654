import ImgLib from "./ImgLib";

const Webinars = {
  data: [
    {
      id: 21,
      title:
        "Calidad de la atención sanitaria comunicada por los usuarios: resutlados de la primera ronda de la encuesta People's Voice Survey en 14 países",
      description: "",
      descriptionENG: "",
      date: "25/06/24",
      time: "12PM EST",
      image: ImgLib[31],
      bannerImage: ImgLib[31],
      language: "ESP",
      proffesorId: [2],
      registerLink:
        "",
      seeVideoESP: "",
      seeVideoENG: "https://www.youtube.com/watch?v=Ndb2XIMWF2w",
      files: "",
    },
    {
      id: 20,
      title:
        "Confianza de la población en los sistemas de salud en 15 países: resultados de la primera ronda de la encuesta People's Voice Survey",
      description: "",
      descriptionENG: "",
      date: "30/04/2024",
      time: "12PM EST",
      image: ImgLib[30],
      bannerImage: ImgLib[30],
      language: "ESP",
      proffesorId: [17],
      registerLink:
        "",
      seeVideoESP: "",
      seeVideoENG: "https://www.youtube.com/watch?v=C3UgEWDdB00",
      files: "",
    },
    {
      id: 19,
      title:
        "Introducción a la serie de The Lancet GH Acerca de  la encuesta 'La voz de la gente' sobre el desempeño del sistema de salud",
      description: "",
      descriptionENG: "",
      date: "27/02/2024",
      time: "12PM EST",
      image: ImgLib[29],
      bannerImage: ImgLib[29],
      language: "ESP",
      proffesorId: [20,2],
      registerLink:
        "",
      seeVideoESP: "https://www.youtube.com/watch?v=4nF6AZvBd-I",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 18,
      title:
        "Una investigación sobre las desigualdades en la cobertura y la calidad del sistema sanitario",
      description: "",
      descriptionENG: "",
      date: "28/11/2023",
      time: "12PM EST",
      image: ImgLib[28],
      bannerImage: ImgLib[28],
      language: "ESP",
      proffesorId: [1,28],
      registerLink:
        "",
      seeVideoESP: "",
      seeVideoENG: "https://www.youtube.com/watch?v=6zaGLnyXAWc",
      files: "",
    },
    {
      id: 17,
      title:
        "Adaptación cualitativa de encuestas para uso local",
      description: "",
      descriptionENG: "",
      date: "26/09/2023",
      time: "12PM EST",
      image: ImgLib[27],
      bannerImage: ImgLib[27],
      language: "ESP",
      proffesorId: [20,28],
      registerLink:
        "",
      seeVideoESP: "https://youtu.be/CqSTvcHPomk",
      seeVideoENG: "",
      files: "https://drive.google.com/drive/folders/1WBzSoqp0eq1MyZzK-MOjma0xDRzmx_H8?usp=sharing",
    },
    {
      id: 16,
      title:
        "Una mirada a los análisis cuasi-experimentales",
      description: "",
      descriptionENG: "",
      date: "29/08/2023",
      time: "12PM EST",
      image: ImgLib[26],
      bannerImage: ImgLib[26],
      language: "ESP",
      proffesorId: [27],
      registerLink:
        "",
      seeVideoESP: "https://youtu.be/InwjoFafHik",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 15,
      title:
        "Patient safety as a strategy for quality care",
      description: "",
      descriptionENG: "",
      date: "27/06/2023",
      time: "12PM EST",
      image: ImgLib[25],
      bannerImage: ImgLib[25],
      language: "ESP",
      proffesorId: [2,26],
      registerLink:
        "",
      seeVideoESP: "https://youtu.be/ZLT2jVMAVbQ",
      seeVideoENG: "",
      files: "https://drive.google.com/drive/folders/1erlRRbd6E2ZGUE1ujtDHXVROBrQ8QzkJ?usp=sharing",
    },
    {
      id: 14,
      title:
        "Pagos informales en establecimientos de salud en Perú en 2018: análisis de una encuesta transversal",
      description: "",
      descriptionENG: "",
      date: "30/05/2023",
      time: "12PM EST",
      image: ImgLib[24],
      bannerImage: ImgLib[24],
      language: "ESP",
      proffesorId: [21],
      registerLink:
        "",
      seeVideoESP: "https://youtu.be/DET4eq7TDJs",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 13,
      title:
        "Construyendo sistemas de Salud Resilentes en América Latina y el Caribe: Lecciones aprendidas de la pandemia Covid-19",
      description: "",
      descriptionENG: "",
      date: "25/04/2023",
      time: "12PM EST",
      image: ImgLib[23],
      bannerImage: ImgLib[23],
      language: "ESP",
      proffesorId: [8],
      registerLink: "",
      seeVideoESP: "https://youtu.be/vO-xJI8soIU",
      seeVideoENG: "",
      files:
        "https://drive.google.com/drive/folders/12DOK2rL4zzRLVo67p9ZntHVoeuECFpzy?usp=sharing",
    },
    {
      id: 12,
      title:
        "Cobertura Universal Efectiva y Mejoramiento de la calidad en APS: Un desafío para latinoamerica",
      description: "",
      descriptionENG: "",
      date: "28/03/2023",
      time: "12PM EST",
      image: ImgLib[22],
      bannerImage: ImgLib[22],
      language: "ESP",
      proffesorId: [4],
      registerLink: "",
      seeVideoESP: "https://youtu.be/8vyHG35T_Go",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 11,
      title:
        "Contribución de la investigación en políticas públicas para la mejora de la calidad de atención en salud",
      description: "",
      descriptionENG: "",
      date: "28/02/2023",
      time: "12PM EST",
      image: ImgLib[21],
      bannerImage: ImgLib[21],
      language: "ESP",
      proffesorId: [22, 23, 24, 25],
      registerLink: "",
      seeVideoESP: "https://youtu.be/qHpYOGnMjy8",
      seeVideoENG: "",
      files:
        "https://drive.google.com/drive/folders/13EYj6LVifdqca5qTbhjjJQL5gl_ykWsL?usp=sharing",
    },
    {
      id: 10,
      title:
        "Una revisión sistemática de las medidas de calidad de la atención en América Latina",
      description: "",
      descriptionENG:
        "A systematic review of health care quality measures in Latin America",
      date: "6/12/2022",
      time: "12PM EST",
      image: ImgLib[20],
      bannerImage: ImgLib[20],
      language: "ESP",
      proffesorId: [20, 21],
      registerLink:
        "https://zoom.us/webinar/register/WN_k8TPYk3NQFSx2OhzO2Ny0w",
      seeVideoESP: "",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 9,
      title: "Métodos de mejora en salud a gran escala",
      description: "",
      descriptionENG:
        "Use of routine health data for health system assessments",
      date: "25/10/2022",
      time: "12PM EST",
      image: ImgLib[19],
      bannerImage: ImgLib[19],
      language: "ESP",
      proffesorId: [2, 17],
      registerLink:
        "https://zoom.us/webinar/register/WN_ehAICQNKQcuAXnCgt9ulNw",
      seeVideoESP: "",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 8,
      title:
        "Uso de datos de salud rutinarios para la evaluación de sistemas de salud",
      description: "",
      descriptionENG:
        "Use of routine health data for health system assessments",
      date: "27/09/2022",
      time: "12PM EST",
      image: ImgLib[18],
      bannerImage: ImgLib[18],
      language: "ESP/ENG",
      proffesorId: [19],
      registerLink: "",
      seeVideoESP: "",
      seeVideoENG: "",
      files: "",
    },
    {
      id: 7,
      title:
        "Perspectivas en el rediseño de la prestación de los sistemas de salud",
      description: "",
      descriptionENG: "",
      date: "28/06/2022",
      time: "12PM EST",
      image: ImgLib[17],
      bannerImage: ImgLib[17],
      language: "ESP/ENG",
      proffesorId: [15],
      registerLink: "",
      seeVideoESP: "",
      seeVideoENG: "https://youtu.be/1fGE83mWonA",
      files:
        "https://drive.google.com/drive/folders/1WLv4rfgDr4NsN3ApfJoVi6U26qDN34tW?usp=sharing",
    },
    {
      id: 6,
      title: "Regulación en calidad de atención y sistemas de salud",
      description: "",
      descriptionENG: "Regulation in quality of care and health systems",
      date: "31/05/2022",
      time: "12PM EST",
      image: ImgLib[16],
      bannerImage: ImgLib[16],
      language: "ESP",
      proffesorId: [3],
      registerLink: "",
      seeVideoESP: "https://youtu.be/dISTFxBkQvM",
      seeVideoENG: "",
      files:
        "https://drive.google.com/file/d/1edZNQy7M48sBCUNg5A0_u_jRq6MgtVIv/view?usp=sharing",
    },
    {
      id: 5,
      title: "Aspectos críticos de análisis secundario de registros de salud",
      titleeng: "Critical Aspects of Secondary Analysis of Healthcare Records",
      description: "",
      descriptionENG: "",
      date: "26/04/2022",
      time: "12PM EST",
      image: ImgLib[9],
      bannerImage: ImgLib[9],
      language: "ESP",
      proffesorId: [0],
      registerLink: "",
      seeVideoESP: "https://youtu.be/ZpVwDS9jkyU",
      seeVideoENG: "",
      files:
        "https://drive.google.com/drive/folders/19Z0z2h1qNGocxvwFlRYaMbK2Cn9ItyBj?usp=sharing",
    },
    {
      id: 4,
      title:
        "Una visión para la transformación hacia la calidad en los sistemas de salud",
      titleeng: "",
      description: "",
      descriptionENG: "",
      date: "29/03/2022",
      time: "12PM EST",
      image: ImgLib[8],
      bannerImage: ImgLib[8],
      language: "ESP",
      proffesorId: [7],
      registerLink: "",
      seeVideoESP: "https://youtu.be/qfX3mVAp0ZM",
      seeVideoENG: "",
      files:
        "https://drive.google.com/drive/folders/1Aze2uZgFC3RslODvKH1LFaMhOr3HrlPH?usp=sharing",
    },
    {
      id: 3,
      title:
        "Efectividad de las colaborativas para mejorar la calidad: hallazgo de una revisión sistemática de iniciativas en LMIC's",
      titleeng:
        "Effectiveness of collaborative projects to improve quality: a systematic review of initiatives in LMIC's",
      description: "",
      descriptionENG: "",
      date: "22/02/2022",
      time: "12PM EST",
      image: ImgLib[7],
      bannerImage: ImgLib[7],
      language: "ESP",
      proffesorId: [2],
      registerLink: "",
      seeVideoESP: "https://youtu.be/50GhHG0xA8c",
      seeVideoENG: "",
      files:
        "https://drive.google.com/drive/folders/1uma1M2bn7IXWTD7y0m_jSAyyHpOnTh8D?usp=sharing",
    },
    {
      id: 2,
      title:
        "Introducción  a las medidas de calidad de los sistemas de salud en los datos de las encuestas",
      titleeng:
        "An introduction to health system quality measures in survey data",
      description:
        "Este webinar le proveerá una introducción al Qué, Donde y Como de las medidas de cálidad en salud. Le introducirá a un marco de referencia en torno a lo que significa calidad en sistemas de salud, otorgando una vista general de donde se han capturado medidas de calidad de salud en fuentes de datos existentes, identificar como estas medidas existentes pueden ser usadas para entender la eficiencia  del servicio de salud, así como las perpectivas de la poblacion sobre el sistema de salud.",
      descriptionENG: "",
      date: "25/01/2022",
      time: "12:00 EST",
      image: ImgLib[6],
      bannerImage: ImgLib[6],
      language: "ESP/ENG (Simultaneous Translation)",
      proffesorId: [1],
      seeVideoESP: "https://youtu.be/THp0z9adPs8",
      seeVideoENG: "https://youtu.be/uXdBCuIvZfk",
      files:
        "https://drive.google.com/drive/folders/1uey6MLMC9dkpzF4Q2REMdetKv87LHIUN?usp=sharing",
    },
    {
      id: 1,
      title: "Panel: El desafio de la calidad de los sistemas de salud en LAC",
      date: "2/12/2021",
      image: ImgLib[1],
      language: "ES/EN",
      proffesorId: [16, 17, 8, 18],
      seeVideoESP: "https://youtu.be/5LMMEs8y_Rs",
    },
  ],
};

export default Webinars;
